/* eslint-disable */
import { requestAwsInfo, EXECUTE_RESULT } from "@/constant/config";
import axios from "axios";
//import moment from "moment";
//const queryString = require('query-string');
export default {
    getCostDailyAsync(requestParams) {
        //let queryParams = queryString.stringify(requestParams);
        return axios({
            method: "POST",
            url: `${requestAwsInfo.getCostDailyAsync}`,
            data: requestParams,
            withCredentials: true,
        });
    },
    getCostExplorerMetrics(requestParams) {
        return axios({
            method: "POST",
            url: `${requestAwsInfo.getCostExplorerMetrics}`,
            data: requestParams,
            withCredentials: true,
        });
    }
}